import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
    const educationDetails = [
        {
            yearRange: "2011 - 2016",
            title: "Bachelor in Computer Science",
            place: "Universidade Federal do Espirito Santo",
            desc: "Emphasis on software architecture, ontologies, and web development. I successfully completed my degree with a commendable 3.5 GPA.",
        },
        {
            yearRange: "2015",
            title: "Exchange Student - Bachelor in Computer Science",
            place: "University of Toronto",
            desc: "I had a unique opportunity as an exchange student at the University of Toronto, where I joined a group of Brazilian students chosen to spend a year studying at UofT.",
        }
    ];

    const experienceDetails = [
        {
            yearRange: "2023 - current",
            title: "Senior Software Engineer",
            place: "Alby",
            desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        },
        {
            yearRange: "Mar 2022 - Jul 2023",
            title: "Staff Engineer",
            place: "Origin",
            desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        },
        {
            yearRange: "May 2021 - Mar 2022",
            title: "Engineering Manager",
            place: "Origin",
            desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        },
        {
            yearRange: "May 2020 - May 2021",
            title: "Senior Software Engineer",
            place: "Origin",
            desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        },
        {
            yearRange: "Apr 2017 - May 2020",
            title: "Tech Lead",
            place: "Liber Capital",
            desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        },
    ];

    const skills = [
        {
            name: "Python",
            percent: 100,
        },
        {
            name: "AWS",
            percent: 90,
        },
        {
            name: "CI/CD",
            percent: 90,
        },
        {
            name: "Terraform",
            percent: 90,
        },
        {
            name: "Kubernetes",
            percent: 80,
        },
        {
            name: "React",
            percent: 70,
        },
    ];

    return (
        <section id="resume" className="section">
            <div className="container">
                {/* Heading */}
                <p className=" text-center mb-2 wow fadeInUp">
                    <span className="bg-primary text-dark px-2">Resume</span>
                </p>
                <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
                    A summary of My Resume
                </h2>
                {/* Heading end*/}
                <div className="row g-5 mt-5">
                    {/* My Education */}
                    <div className="col-lg-6 wow fadeInUp">
                        <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
                        <div className="border-start border-2 border-primary ps-3">
                            {educationDetails.length > 0 &&
                                educationDetails.map((value, index) => (
                                    <div key={index}>
                                        <h3 className="text-5">{value.title}</h3>
                                        <p className="mb-2">
                                            {value.place} / {value.yearRange}
                                        </p>
                                        <p className="text-muted">{value.desc}</p>
                                        <hr className="my-4"/>
                                    </div>
                                ))}
                        </div>
                    </div>
                    {/* My Experience */}
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
                        <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
                        <div className="border-start border-2 border-primary ps-3">
                            {experienceDetails.length > 0 &&
                                experienceDetails.map((value, index) => (
                                    <div key={index}>
                                        <h3 className="text-5">{value.title}</h3>
                                        <p className="mb-2">
                                            {value.place} / {value.yearRange}
                                        </p>
                                        {/*<p className="text-muted">{value.desc}</p>*/}
                                        <hr className="my-4"/>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                {/* My Skills */}
                <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
                <div className="row gx-5">
                    {skills.length > 0 &&
                        skills.map((skill, index) => (
                            <div className="col-md-6 wow fadeInUp" key={index}>
                                <p className="fw-500 text-start mb-2">
                                    {skill.name}{" "}
                                    <span className="float-end">{skill.percent}%</span>
                                </p>
                                <div className="progress progress-sm mb-4">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{width: skill.percent + "%"}}
                                        aria-valuenow={skill.percent}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
                <p className="text-center mt-5 wow fadeInUp">
                    <a
                        className="btn btn-outline-dark shadow-none rounded-0"
                        href={resumeFile}
                        download
                    >
                        Download CV
                    </a>
                </p>
            </div>
        </section>
    );
};

export default Resume;
