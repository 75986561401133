import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
    const faqsData = [
        {
            question: "Are you open for freelance projects?",
            answer: "Not at the moment. Right now I'm 100% focused on the company I'm working on and some open-source projects."
        },
        {
            question: "Do you provide 1:1 mentoring?",
            answer: "Yes, I do. I address this on an individual basis because each person's needs are unique. Contact me using the form below if you are interested."
        },
        {
            question: "Do you have any Python or AWS course?",
            answer: "Not yet. I'm developing one focused on those two things, as well as infrastructure-as-a-code and large language models (LLMs). Keep your eyes on!"
        }
    ];

    return (
        <section id="faq" className="section bg-light">
            <div className="container">
                <div className="row gy-5">
                    <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
                        {/* Heading */}
                        <p className="text-center text-lg-start mb-2">
                            <span className="bg-primary text-dark px-2">FAQ</span>
                        </p>
                        <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
                            Have any questions?
                        </h2>
                        {/* Heading end*/}

                        <Accordion flush defaultActiveKey="0">
                            {faqsData.length > 0 &&
                                faqsData.map((faq, index) => (
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body
                                            dangerouslySetInnerHTML={{__html: faq.answer}}
                                        ></Accordion.Body>
                                    </Accordion.Item>
                                ))}
                        </Accordion>
                    </div>
                    <div
                        className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
                        data-wow-delay="1s"
                    >
                        {" "}
                        <img
                            className="img-fluid"
                            src="images/faq.png"
                            title="FAQ"
                            alt="faq"
                        />{" "}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQs;
